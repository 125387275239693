@import "./variables";
@layer bootstrap, primereact, custom;

// Bootstrap related vars
$red: $brand-danger;
$green: $brand-success;
$blue: $brand-primary;

$gray-100: #f5f5f5 !default;
$gray-800: $sj-black;
$gray-900: #212529 !default;

$body-color: #2b2922;

$input-bg: $sj-off-white;
$input-border-color: #adaaa0;
$input-placeholder-color: $sj-greyish;

// Navbar
$navbar-dark-color: $sj-off-white;
$navbar-dark-hover-color: $sj-yellow;
$navbar-dark-active-color: $sj-yellow;

// Navs

// Dropdowns
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

@import "./bootstrapConfig.scss" layer(bootstrap);
@import "~trix/dist/trix";
@import "~react-toastify/dist/ReactToastify";
@import "./fonts";
@import "./trix_rewrites";
@import "./trix_editor_typography";
@import "./toast";
@import "./bootstrap";
@import "./modals";
@import "./ql-editor-rewrites";

// PrimeReact
@import "~primeicons/primeicons.css";
@import "~@securityjourney/primereact-sass-theme/themes/bootstrap4/sj-bootstrap-light/blue/theme.scss"
  layer(primereact);
@import "./primereactOverrides";

@layer custom {
  body {
    padding-right: 0 !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    top: 0 !important;
    -webkit-font-smoothing: antialiased;

    .intercom-launcher {
      bottom: 5px;
    }

    iframe.intercom-launcher-frame {
      bottom: 5px !important;
    }

    #goog-gt-tt {
      display: none !important;
    }

    .skiptranslate {
      bottom: 0;
      top: initial;
    }

    .skiptranslate {
      bottom: 0;
      top: initial;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 500;
    }

    th {
      font-weight: 500;
    }
  }

  #react-root {
    // mainly for ie11
    display: flex;
    flex-direction: column;
  }

  .MainApp {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  // style to make it height 100
  .Activities {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .ActivitiesDashboard {
      flex-grow: 1;
      position: relative;
      &-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-top: 3vh;
        padding-bottom: 3vh;
      }
      .container {
        height: 100%;
        .row {
          height: 100%;
        }
      }
    }
  }

  .color-danger {
    color: $brand-danger;
  }

  .color-success {
    color: $brand-success;
  }

  .btn {
    border-radius: $sharp-border-radius;
  }

  .btn-breakfix {
    background-color: $breakfix-primary-green;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: $breakfix-primary-green;
      border-color: var(--bs-btn-hover-border-color);
    }
  }

  .btn-success {
    color: #ffffff;
  }

  input.form-control,
  select.form-select,
  .card {
    border-radius: $sharp-border-radius;
  }

  .react-joyride__spotlight {
    background-color: #a0a0a0 !important;
  }

  .hidden {
    display: none;
  }

  .visually-hidden {
    // new reactstrap hidden class that came without styling
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  .card-body a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .CookieConsent {
    z-index: 1 !important;
  }

  // Reset for Prime React
  .p-dialog-footer,
  .p-card .p-card-footer {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  .p-togglebutton .p-button {
    background: transparent;
    border: 1px solid $brand-primary;
    color: $brand-primary;
  }

  .p-togglebutton.p-highlight .p-button {
    background-color: $brand-primary;
    color: #fff;
  }

  .p-tooltip .p-tooltip-text {
    background-color: #fff;
    color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 12px;
  }

  .p-tooltip-arrow {
    border-right: #fff solid 4px;
  }

  .p-checkbox.p-highlight .p-checkbox-box {
    border-color: $brand-primary;
    background: $brand-primary;
  }

  .p-datepicker-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
